// import { BlankLayout } from '@/layouts';
// import { BlankLayout, PageView } from '@/layouts';

export default [
  {
    path: '/auth',
    component: () => import('@/layouts/BlankLayout'),
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/system/auth/Login/index.vue'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/system/auth/Register/index.vue'),
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import('@/views/system/auth/RegisterResult/index.vue'),
      },
    ],
  },
];
