import { ThemeStyle, themeConfig, useStore } from '@/store/system/theme';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const visible = ref(false);
    const handleOk = () => {
      visible.value = false;
    };
    const show = () => {
      visible.value = true;
    };
    const themeStore = useStore();
    console.log('themeStore', themeStore.themeStyle);
    const themeStyle = ref(themeStore.themeStyle);
    const primaryColor = ref(themeStore.primaryColor);

    const styleOptions = ref(themeConfig);
    const handleThemeStyleChange = (value: ThemeStyle) => {
      themeStore.setTheme(value);
    };
    const handleColorChange = (e?: Event & { target: { value: string } }) => {
      primaryColor.value = e?.target!.value as string;
      console.log(e, primaryColor.value);
      themeStore.setPrimaryColor(primaryColor.value);
    };
    handleThemeStyleChange(themeStore.themeStyle);
    themeStore.setPrimaryColor(themeStore.primaryColor);
    return {
      visible,
      handleOk,
      styleOptions,
      themeStyle,
      handleThemeStyleChange,
      primaryColor,
      handleColorChange,
      show,
    };
  },
  render() {
    const {
      styleOptions,
      handleOk,
      handleThemeStyleChange,
      handleColorChange,
    } = this;
    return (
      <a-modal
        v-model:visible={this.visible}
        title="设置"
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
      >
        <a-form-item label="风格">
          <a-select
            v-model:value={this.themeStyle}
            onChange={handleThemeStyleChange}
          >
            {styleOptions.map((item) => {
              return (
                <a-select-option value={item.style} key={item.style}>
                  {item.style}
                </a-select-option>
              );
            })}
          </a-select>
        </a-form-item>
        <a-form-item label="主题色">
          <input
            value={this.primaryColor}
            type="color"
            onChange={(e) =>
              handleColorChange(e as Event & { target: { value: string } })
            }
          />
        </a-form-item>
      </a-modal>
    );
  },
});
