// import hmacsha256 from 'crypto-js/hmac-sha256';

// const HMACSHA256KEY = '1001';

// export function hashSHA246(params: Common.Params) {
//   // 通过 hmacsha256 生成散列字符串
//   return hmacsha256(JSON.stringify(params), HMACSHA256KEY).toString();
// }

export function getPublicKey(): Promise<string> {
  return new Promise((resolve, reject) => {
    fetch('/api/system/encrypt/getPublicKey')
      .then((res) => res.text())

      .then((res) => {
        localStorage.setItem('publicKey', res);
        resolve(res as unknown as string);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
