import { computed, defineComponent, ref } from 'vue';
import './index.less';
import { useStore } from '../../../store/system/user/index';
import Setting from '../Setting';

export default defineComponent({
  setup() {
    const settingRef = ref<InstanceType<typeof Setting>>();
    const userStore = useStore();
    const quota = computed(() => userStore.userInfo.quota);

    const handleShowSetting = () => {
      settingRef.value?.show();
    };
    return {
      quota,
      userStore,
      handleShowSetting,
      settingRef,
    };
  },
  render() {
    const { userStore, handleShowSetting } = this;
    const handleGo = (name: string) => {
      this.$router.push({ name });
    };
    const handleSignOut = () => {
      userStore.logout();
      this.$router.push({ name: 'login' });
    };
    const path = this.$route.path;
    return (
      <div class="menu">
        <div class="list">
          <div
            onClick={() => handleGo('chat')}
            class={`item ${path.startsWith('/chat') ? 'active' : ''}`}
          >
            <c-icon type="chat" /> 对话
          </div>
          <div
            onClick={() => handleGo('image')}
            class={`item ${path.startsWith('/image') ? 'active' : ''}`}
          >
            <c-icon type="create-image" /> 绘画
          </div>
          <div
            onClick={() => handleGo('quota')}
            class={`item ${path.startsWith('/quota') ? 'active' : ''}`}
          >
            <c-icon type="jifen" /> 剩余积分
            <span class="quota">{this.quota}</span>
            <plus-circle-outlined />
          </div>
          <div onClick={handleShowSetting} class="item">
            <setting-outlined /> 设置
          </div>
          <div class="item">
            <c-icon type="user" /> 账号
            <span>{userStore.userInfo.email}</span>
          </div>
          {/* <div onClick={() => handleGo('about')} class="item">
            <c-icon type="ai" /> 加群联系作者
          </div> */}

          <div onClick={handleSignOut} class="item">
            <logout-outlined /> 退出登录
          </div>
        </div>
        <Setting ref="settingRef" />
      </div>
    );
  },
});
