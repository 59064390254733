import { computed, defineComponent, getCurrentInstance, ref } from 'vue';

import './index.less';
import config from '@/config';
import MultipleSession from './MultipleSession';
import Menu from './Menu';
import { useStore } from '@/store/system/theme';
import { useStore as useSystemStore } from '@/store/system/system';
// import holographicArchives from "@/assets/images/system/holographic-archives.png"

export default defineComponent({
  setup() {
    const instance = getCurrentInstance()!;
    const handleFullscreen = () => {
      document.body.requestFullscreen();
    };
    const handleFullscreenExit = () => {
      document.exitFullscreen();
    };
    const themeStore = useStore();
    const handleChangeSidebar = () => {
      themeStore.changeIsShowSidebar();
    };
    const isShowSidebar = computed(() => themeStore.isShowSidebar);

    const systemStore = useSystemStore();
    const isMobile = computed(() => systemStore.isMobile);
    return {
      handleFullscreen,
      handleFullscreenExit,
      handleChangeSidebar,
      isShowSidebar,
      isMobile,
    };
  },

  methods: {},
  render() {
    const { handleChangeSidebar } = this;
    const path = this.$route.path;
    return (
      <div
        class={`system-nav ${
          this.isShowSidebar ? 'sidebar-open' : 'sidebar-close'
        }`}
      >
        <div class="head">
          <div class="logo">
            <div class="logo-img">
              <c-icon type="ai" />
            </div>
            {config.title}
          </div>
          {!this.isMobile && (
            <div onClick={handleChangeSidebar} class="sidebar">
              <c-icon type="sidebar" />
            </div>
          )}
        </div>

        {path.startsWith('/chat') && <MultipleSession />}
        <Menu />
      </div>
    );
  },
});
