export function isMobileDevice(): boolean {
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    'Android',
    'iPhone',
    'iPad',
    'Windows Phone',
    'BlackBerry',
    'Nokia',
    'Symbian',
    'Mobile',
  ];

  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}
