import { computed, defineComponent, getCurrentInstance, ref } from 'vue';

import { useStore } from '@/store/ai/chat';
import './index.less';
import { formatTime } from '@/utils/date';

export default defineComponent({
  emits: ['change'],
  setup() {
    const chatStore = useStore();
    const list = computed(() => chatStore.getChats());

    const instance = getCurrentInstance()!;
    const chatIndex = computed(() => chatStore.getChatIndex());
    const chatId = computed(() => chatStore.chatId);
    if (!chatId.value) {
      chatStore.setChatIndex(chatIndex.value);
    }
    const isEdit = ref(false);
    const editData = ref({
      name: '',
      index: 0,
    });

    const handleFullscreen = () => {
      document.body.requestFullscreen();
    };
    const handleFullscreenExit = () => {
      document.exitFullscreen();
    };

    /**
     * 保存数据
     */
    const save = () => {
      chatStore.setChats(list.value);
      chatStore.setChatIndex(chatIndex.value);
    };
    // /**
    //  * 打开时初始化数据
    //  */
    // const init = () => {
    //   // list.value = [...getChats()]
    // };
    /**
     * 新增聊天
     */
    const handleAdd = () => {
      chatStore.addChat(() => instance.proxy!.$eventBus.emit('chatChange'));
      updateList();
    };
    /**
     * 删除聊天
     * @param {*} id
     */
    const handleRemove = (event: Event, id: string) => {
      event.stopPropagation();
      const index = list.value.findIndex((item) => item.id === id);
      chatStore.removeChat(index);
      if (chatStore.chatIndex === index) {
        const list = chatStore.chats
          .map((item) => {
            return {
              ...item,
            };
          })
          .sort((a, b) => b.createdAt - a.createdAt);
        const id = list[0].id;
        const newIndex = chatStore.chats.findIndex((item) => item.id === id);
        chatStore.setChatIndex(newIndex);
        instance.proxy!.$eventBus.emit('chatChange');
      }
    };
    /**
     * 编辑聊天名称
     * @param {*} index
     */
    const handleEdit = (event: Event, id: string) => {
      event.stopPropagation();
      const index = list.value.findIndex((item) => item.id === id);
      editData.value.index = index;
      editData.value.name = list.value[index].name;
      isEdit.value = true;
    };
    /**
     * 点击选择聊天
     * @param {*} id
     */
    const handleSelect = (id: string) => {
      const index = list.value.findIndex((item) => item.id === id);
      chatStore.setChatIndex(index);
      instance.proxy!.$eventBus.emit('chatChange');
    };
    /**
     * 更新聊天
     */
    const updateList = () => {
      // list.value = [...chatStore.getChats()]
    };
    /**
     * 保存修改的聊天名称
     */
    const handleSaveName = () => {
      list.value[editData.value.index].name = editData.value.name;
      isEdit.value = false;
      resetEditData();
    };
    /**
     * 关闭聊天
     */
    const handleCancelName = () => {
      isEdit.value = false;
      resetEditData();
    };
    /**
     * 初始化聊天编辑数据
     */
    const resetEditData = () => {
      editData.value.index = 0;
      editData.value.name = '';
    };

    return {
      handleFullscreen,
      handleFullscreenExit,
      chatStore,
      list,
      chatIndex,
      isEdit,
      editData,
      chatId,
      save,
      handleAdd,
      handleSelect,
      handleEdit,
      handleRemove,
      handleSaveName,
      handleCancelName,
    };
  },

  render() {
    const {
      handleAdd,
      handleSelect,
      handleEdit,
      handleRemove,
      handleSaveName,
      handleCancelName,
      editData,
      chatIndex,
      isEdit,
    } = this;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    oneWeekAgo.setHours(0, 0, 0, 0);
    const list = this.list as AI.ChatNode[];
    const todayList: AI.ChatNode[] = [];
    const yesterdayList: AI.ChatNode[] = [];
    const last7DaysList: AI.ChatNode[] = [];
    const olderList: AI.ChatNode[] = [];
    list.forEach((item) => {
      // chat的时间
      const date = new Date(item.createdAt || new Date().getTime());
      if (date >= today) {
        todayList.push(item);
      } else if (date >= yesterday) {
        yesterdayList.push(item);
      } else if (date >= oneWeekAgo) {
        last7DaysList.push(item);
      } else {
        olderList.push(item);
      }
    });

    const renderItem = (item: AI.ChatNode, format = 'HH:mm') => {
      const { handleRemove, handleEdit, chatId, handleSelect } = this;
      return (
        <div
          onClick={() => handleSelect(item.id)}
          key={item.id}
          class={`btn ${chatId === item.id ? 'active' : ''}`}
        >
          <div class="left">
            <message-outlined />
            <text class="name row-hidden">{item.name}</text>
            <text class="date row-hidden">
              {formatTime(new Date(item.createdAt), format)}
            </text>
          </div>
          <div class="right">
            <edit-outlined
              onClick={(event: Event) => handleEdit(event, item.id)}
              class="right-btn"
            />

            <delete-outlined
              onClick={(event: Event) => handleRemove(event, item.id)}
              class="right-btn"
            />
          </div>
        </div>
      );
    };
    return (
      <div class="multiple-session">
        <div onClick={handleAdd} class="btn add">
          <plus-outlined />
          <text class="name">新建聊天</text>
        </div>
        <div class="list">
          {todayList.length > 0 && <div class="date-title">今天</div>}
          {todayList
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((item) => {
              return renderItem(item);
            })}
          {yesterdayList.length > 0 && <div class="date-title">昨天</div>}
          {yesterdayList
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((item) => {
              return renderItem(item);
            })}
          {last7DaysList.length > 0 && <div class="date-title">最近7天</div>}
          {last7DaysList
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((item) => {
              return renderItem(item, 'YYYY-MM-DD HH:mm');
            })}
          {olderList.length > 0 && <div class="date-title">更久</div>}
          {olderList
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((item) => {
              return renderItem(item, 'YYYY-MM-DD HH:mm');
            })}

          <a-modal
            visible={isEdit}
            title="修改名称"
            onOk={handleSaveName}
            onCancel={handleCancelName}
          >
            <div class="slot-content">
              <a-input v-model:value={editData.name} />
            </div>
          </a-modal>
        </div>
      </div>
    );
  },
});
