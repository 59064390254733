export default [
  {
    path: '/',
    redirect: '/chat',
  },
  {
    name: 'chat',
    path: '/chat',
    component: () => import('@/views/ai/chat'),
  },
  {
    name: 'image',
    path: '/image',
    component: () => import('@/views/ai/image'),
  },
  {
    name: 'quota',
    path: '/quota',
    component: () => import('@/views/quota'),
  },
  {
    name: 'about',
    path: '/about',
    component: () => import('@/views/about'),
  },
];
