import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { notification } from 'ant-design-vue';

import { useStore } from '@/store/system/user';
import { ACCESS_TOKEN, EXPIRE_IN } from '@/store/system/user/const';
import router from '@/router';
import { loginRoutePath } from '@/permission';
import { getPublicKey } from './verification';
import JSencrypt from 'jsencrypt';
export const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL;
// 设置请求头和请求路径
axios.defaults.baseURL = API_BASE_URL;
axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

(async () => {
  localStorage.setItem(EXPIRE_IN, await getNewExpireData());
})();

setInterval(async () => {
  localStorage.setItem(EXPIRE_IN, await getNewExpireData());
}, 10 * 1000);
axios.interceptors.request.use(
  (config): AxiosRequestConfig<unknown> => {
    const userStore = useStore();
    const { token } = userStore.userInfo;

    if (token) {
      config.headers![ACCESS_TOKEN] = `Bearer ${token}`;
      config.headers![EXPIRE_IN] = localStorage.getItem(EXPIRE_IN)!;
    }
    return config;
  },
  (error) => {
    return error;
  }
);

// 异常拦截处理器
const errorHandler = (error: AxiosError) => {
  if (error.response) {
    const userStore = useStore();
    const data = error.response.data as Common.ResponseData<unknown>;
    const { token } = userStore.userInfo;
    if (error.response.status === 403) {
      notification.error({
        message: '未登录',
        description: data.msg,
      });
      router.push(loginRoutePath);
    }
    if (error.response.status === 401 && !data.data) {
      notification.error({
        message: '登录失效',
        description: data.msg,
      });
      const reload = () => {
        setTimeout(() => {
          router.push(loginRoutePath);
        }, 1500);
      };
      if (token) {
        userStore.deleteToken();
      }
      reload();
    }
  }
  return Promise.reject(error);
};
// 响应拦截
axios.interceptors.response.use((response) => {
  if (response.data && response.data.errorCode !== 0) {
    notification.error({
      message: '请求失败',
      description: response.data?.msg,
    });
    return Promise.reject(response);
  }

  if (!response || response?.data === undefined) {
    return response;
  }

  getNewExpireData();
  return response.data.data;
}, errorHandler);

export default axios;

export async function getNewExpireData(): Promise<string> {
  // 获取保存的公钥
  const publicKey = localStorage.getItem('publicKey') || (await getPublicKey());

  //实例化 jsencrypt
  const jsencrypt = new JSencrypt();
  // 对实例化对象设置公钥
  jsencrypt.setPublicKey(publicKey!);
  // 通过公钥对数据加密
  const encrypt = jsencrypt.encrypt(
    JSON.stringify({
      // id,
      // quota,
      expireId: new Date(new Date().getTime() + 56 * 1000).getTime(),
    })
  );
  return encrypt as string;
}
