import { defineStore } from 'pinia';
import { Permission } from '@fhtwl-admin/system';
import { ACCESS_TOKEN } from './const';
import { login, logout } from '@/api/system/auth';
import { getUserInfo } from '@/api/system/user';

interface UserInfo {
  email: string;
  password: string;
  code: string;
}
const defaultUser = {
  token: localStorage.getItem(ACCESS_TOKEN),
  name: '',
  avatar: '',
  role: undefined as UserRes.StoreRole | undefined,
  info: undefined as System.UserInfo | undefined,
  email: '',
  userInfo: {} as UserRes.GetUserInfo,
  quota: 0,
  id: undefined as number | undefined,
};

export const useStore = defineStore('user', {
  state: () => ({
    userInfo: {
      ...defaultUser,
    },
  }),
  getters: {
    nameLength: (state) => state.userInfo.name.length,
  },
  actions: {
    // 登录
    async login(userInfo: UserInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((res) => {
            console.log(res);
            const token = res;
            this.userInfo.token = token;
            localStorage.setItem(ACCESS_TOKEN, token);
            this.getInfo();
            resolve(token);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    },
    // 退出登录
    async logout() {
      this.userInfo.token = '';
      this.userInfo = {
        ...defaultUser,
      };
      this.deleteToken();
      // return new Promise((resolve, reject) => {
      //   logout()
      //     .then(() => {
      //       this.token = '';
      //       this.deleteToken()
      //       resolve(undefined);
      //     })
      //     .catch((error: Error) => {
      //       reject(error);
      //     });
      // });
    },
    getInfo(): Promise<UserRes.GetUserInfo> {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((response) => {
            const userInfo = response;
            this.userInfo.name = userInfo.name;
            // this.avatar = result.info.avatar;
            this.userInfo.email = userInfo.openid;
            this.userInfo.id = userInfo.id;
            this.userInfo.quota = userInfo.quota;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteToken() {
      this.userInfo = {
        ...defaultUser,
      };
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem('user');
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user', //自定义 Key值
        storage: localStorage, // 选择存储方式
      },
    ],
  },
});
