/**
 * 格式化时间
 * @param time
 * @param format
 * @returns
 */
export function formatTime(time: Date, format = 'YYYY-MM-DD HH:mm:ss'): string {
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const day = time.getDate();
  const hour = time.getHours();
  const minute = time.getMinutes();
  const second = time.getSeconds();
  const millisecond = time.getMilliseconds();

  const padZero = (num: number, len: number) => {
    return num.toString().padStart(len, '0');
  };

  const replacements: Record<string, string> = {
    YYYY: padZero(year, 4),
    YY: padZero(year % 100, 2),
    MM: padZero(month, 2),
    M: month.toString(),
    DD: padZero(day, 2),
    D: day.toString(),
    HH: padZero(hour, 2),
    H: hour.toString(),
    mm: padZero(minute, 2),
    m: minute.toString(),
    ss: padZero(second, 2),
    s: second.toString(),
    SSS: padZero(millisecond, 3),
    S: millisecond.toString(),
  };

  let result = format;
  for (const key in replacements) {
    result = result.replace(key, replacements[key]);
  }

  return result;
}
