import { setCssVariable } from '@/utils/css';
import { isMobileDevice } from '@/utils/system';
import { ConfigProvider } from 'ant-design-vue';
import { defineStore } from 'pinia';

type VariableKey =
  | 'body-bg'
  | 'bg'
  | 'color'
  | 'store-color'
  | 'bg2'
  | 'bg3'
  | 'bg4'
  | 'disabled-color'
  | 'heading-color';

export type ThemeStyle = 'dark' | 'light';
interface ThemeConfigOption {
  style: ThemeStyle;
  variable: {
    [propsName in VariableKey]: string;
  };
}
export const themeConfig: ThemeConfigOption[] = [
  {
    style: 'light',
    variable: {
      'body-bg': '#f0f2f5',
      bg: '#fff',
      color: '#333',
      'store-color': '#000',
      bg2: '#f2f2f2',
      bg3: '#fff',
      bg4: '#fff',
      'disabled-color': '#ccc',
      'heading-color': '#999',
    },
  },
  {
    style: 'dark',
    variable: {
      'body-bg': '#444654',
      bg: '#202123',
      color: '#eee',
      'store-color': '#fff',
      bg2: '#444654',
      bg3: '#343541',
      bg4: '#000',
      'disabled-color': '#999',
      'heading-color': '#ccc',
    },
  },
];

export const useStore = defineStore('theme', {
  state: () => ({
    themeStyle: 'dark' as ThemeStyle,
    primaryColor: '#3c54b4',
    isShowSidebar: false,
  }),
  getters: {},
  actions: {
    setTheme(themeStyle: ThemeStyle) {
      this.themeStyle = themeStyle;
      const { variable } = themeConfig.find(
        (item) => item.style === this.themeStyle
      )!;
      // themeConfig
      for (const key in variable) {
        if (Object.prototype.hasOwnProperty.call(variable, key)) {
          const element = variable[key as VariableKey];
          setCssVariable(key, element);
        }
      }
    },
    setPrimaryColor(primaryColor: string) {
      this.primaryColor = primaryColor;
      setCssVariable('ant-primary-color', this.primaryColor);
      setCssVariable('primary-color', this.primaryColor);
      ConfigProvider.config({
        theme: {
          primaryColor: this.primaryColor,
        },
      });
    },
    changeIsShowSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    initSidebar() {
      if (isMobileDevice()) {
        this.isShowSidebar = false;
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'theme', //自定义 Key值
        storage: localStorage, // 选择存储方式
      },
    ],
  },
});
