import { defineComponent } from 'vue';

import { Empty } from 'ant-design-vue';

export default defineComponent({
  render() {
    return (
      <a-empty style="margin-top: 20px;" image={Empty.PRESENTED_IMAGE_SIMPLE} />
    );
  },
});
