import { createRouter, createWebHashHistory } from 'vue-router';

import common from './modules/common';
import ai from './modules/ai';

const routes = [...common, ...ai];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
