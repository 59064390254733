export default {
  title: '颜良AI',
  description: '颜良AI',
  iconfontUrl: '//at.alicdn.com/t/c/font_4025830_nlcggq9k97.js',
  encryptionKey: 'fhtwl.ai-web',
};

export function isDev() {
  console.log(import.meta.env);
  return import.meta.env.DEV;
}
