import { generateRandomID } from '@/utils/utils';
import { defineStore } from 'pinia';

export const useStore = defineStore('chat', {
  state: () => {
    const chats: AI.ChatNode[] = [
      {
        id: generateRandomID(),
        name: '新的聊天',
        chat: [],
        createdAt: new Date().getTime(),
      },
    ];
    return {
      chats,
      chatIndex: 0,
      chat: [] as AI.Message[],
      model: 'gpt-3.5-turbo',
      chatId: chats[0].id,
    };
  },
  actions: {
    // 创建新的聊天
    getNewChat(name = '新的聊天'): AI.ChatNode {
      return {
        id: generateRandomID(),
        name,
        chat: [],
        createdAt: new Date().getTime(),
      };
    },
    getChats(): AI.ChatNode[] {
      console.log('getChats');
      this.chats = this.chats.length ? this.chats : [this.getNewChat()];
      return this.chats;
    },
    setChats(chats: AI.ChatNode[]) {
      console.log('setChats');
      this.chats = chats?.length > 0 ? [...chats] : [this.getNewChat()];

      console.log('setChats this.chats');
    },
    addChat(callback = () => {}) {
      const chats = this.getChats();
      this.chats = [...chats, this.getNewChat()];
      this.setChatIndex(this.chats.length - 1);
      callback();
    },
    removeChat(index: number) {
      this.chats.splice(index, 1);
    },
    getChat() {
      return this.chat;
    },
    setChat(chat: AI.Message[]) {
      console.log('setChat', chat);
      this.chat = chat;
      const chatIndex = this.getChatIndex();
      this.chats[chatIndex] = {
        ...this.chats[chatIndex],
        chat,
        createdAt: new Date().getTime(),
      };
      if (chat.length === 2) {
        this.chats[chatIndex].name = this.chats[chatIndex].chat[0].message;
      }
      // this.setChats(chats);
    },
    getChatIndex(): number {
      return this.chatIndex;
    },
    setChatIndex(index: number) {
      this.chatIndex = index;
      this.chat = this.chats[index].chat;
      this.chatId = this.chats[index].id;
    },
    setChatModel(model: string) {
      this.model = model;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'chat', //自定义 Key值
        storage: localStorage, // 选择存储方式
      },
    ],
  },
});
