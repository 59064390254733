import MultiTab from '@/components/MultiTab';

import { computed, defineComponent, ref } from 'vue';
import './index.less';
import SystemNav from '@/components/SystemNav';
import { getUserInfo } from '@/api/system/user';
import MobileHead from './MobileHead';
import { useStore as useUserStore } from '@/store/system/user';
import { useStore } from '@/store/system/theme';

export default defineComponent({
  name: 'AdminLayout',
  setup() {
    const userStore = useUserStore();
    userStore.getInfo();
    const isOpen = ref(false);
    const themeStore = useStore();
    const isShowSidebar = computed(() => themeStore.isShowSidebar);
    const handleShowSidebarChange = () => {
      themeStore.changeIsShowSidebar();
    };
    return {
      isOpen,
      isShowSidebar,
      handleShowSidebarChange,
    };
  },

  render() {
    const { handleShowSidebarChange } = this;
    return (
      <>
        {/* <MobileHead
          onChange={handleShowSidebarChange}
          class={this.isShowSidebar ? 'open' : ''}
        /> */}
        <SystemNav class={this.isShowSidebar ? 'open' : ''} />
        <div class={`content-wrap ${this.isShowSidebar ? 'open' : ''}`}>
          <div class="body">
            <router-view key={this.$route.fullPath} />
          </div>
        </div>
      </>
    );
  },
});
