import { defineStore } from 'pinia';

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const useStore = defineStore('theme', {
  state: () => ({
    isMobile,
  }),
  getters: {},
  actions: {},
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'system', //自定义 Key值
        storage: localStorage, // 选择存储方式
      },
    ],
  },
});
